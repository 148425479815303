/*.container {
    background-image: url(https://cdn.shortpixel.ai/client/q_lossy,ret_img/https://approval.studio/wp-content/uploads/2020/04/slider-background-opt.jpg);
    transition: background .3s,border .3s,border-radius .3s,box-shadow .3s;
    transition-property: background, border, border-radius, box-shadow;
    transition-duration: 0.3s, 0.3s, 0.3s, 0.3s;
    transition-timing-function: ease, ease, ease, ease;
    transition-delay: 0s, 0s, 0s, 0s;
}
*/

#email {
    max-width: 350px !important;
}

.color-icon {
    width: 150px;
    height: 150px;
}

.note-text {
    font-family: "Raleway", "Helvetica Neue", Helvetica, Arial, sans-serif !important;
    font-weight: 400;
    color: #6c757d !important;
    font-size: 13px;
    margin-top: 20px !important;
}

.separator-text {
    margin-top: 10px !important;
}