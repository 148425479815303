/*a.navbar-brand {
  white-space: normal;
  text-align: center;
  word-break: break-all;
}*/

/*html {
  font-size: 14px;
}*/
/*@media (min-width: 768px) {
  html {
    font-size: 16px;
  }
}
*/

.box-shadow {
  box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
}

.brand-logo {
    width: 310px;
}

.navbar {
    height: 70px !important;
}


.navbar-brand {
    padding-top: 10px;
    padding-bottom: 10px;
}

.nav-item-try {
    -webkit-tap-highlight-color: transparent;
    list-style: none;
    
    line-height: 90px;
    box-sizing: inherit;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
    position: relative;
    transition: .4s;
    color: #fff;
    background: #ff5e28;
    height: 50px;
    border-radius: 50px;
    margin-left: 50px;
    padding-left: 35px;
    padding-right: 35px;
}

    .nav-item-try a {
        font-family: "Open Sans",sans-serif;
        color: #fff !important;
        opacity: 1;
        letter-spacing: 1px;
        text-transform: uppercase;
        font-weight: 600;
        font-size: .75rem;
    }