/* Provide sufficient contrast against white background */
/*a {
  color: #0366d6;
}

code {
  color: #E01A76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}
*/

@import url('https://fonts.googleapis.com/css2?family=Goudy+Bookletter+1911&family=Raleway&family=Dosis&family=Open+Sans');

/*html {
    font-family: "Goudy Bookletter 1911", Georgia, "Times New Roman", Times, serif !important;
}
*/
h1,h2,h3,h4,h5,b  {
    font-family: "Raleway", "Helvetica Neue", Helvetica, Arial, sans-serif !important;
}

body {
    font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif !important;
}
